import { FeatureFlag } from 'protocol/model/dto_organization_pb';

export const FEATURE_FLAGS_CONFIG = {
  dev: [
    FeatureFlag.FeatureFlag_Show_Order3DReports,
    FeatureFlag.FeatureFlag_Show_OrthoPlanningButton,
    FeatureFlag.FeatureFlag_Show_ImplantPlanningButton,
    FeatureFlag.FeatureFlag_Show_NonDentalFindings,
    FeatureFlag.FeatureFlag_Show_BillingShop,
  ],
  regulatory: [
    FeatureFlag.FeatureFlag_Hide_Probabilities,
    FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    FeatureFlag.FeatureFlag_Hide_CariesSigns,
    FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    FeatureFlag.FeatureFlag_FDA_SubmissionView,
    FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
  ],
};
